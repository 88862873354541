<template>
  <div>
    <!-- <d-tabs :tabs="tabs" v-model="tab" /> -->
    <template v-if="tab.key === 1">
      <div class="mb-4">
        <h1 class="font-bold text-2xl">Groups</h1>
      </div>
      <div class="flex-between">
        <button-group class="space-x-2" :buttons="buttons" />
        <div class="w-32 mr-2">
          <d-select v-model="chartType" :items="chartTypes" />
        </div>
      </div>
      <empty-content :content="$t('No Accounts')" v-if="isEmpty" />
      <div class="flex flex-col gap-2 mt-2" v-else :key="chartType">
        <group-card
          v-for="group of groups"
          :key="group.key"
          :group="group"
          :chartType="chartType"
          :total="groups.length"
        />
      </div>
    </template>
    <!-- <template v-else>
      <div class="w-full" v-for="account in accounts" :key="account._id">
        <template v-if="balances[account._id]">
          <performance-card
            class="mt-4"
            :account="account"
            :balances="balances[account._id]"
          />
        </template>
      </div>
    </template> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmptyContent from '@/components/misc/empty-content'
import GroupCard from '@/components/apps/accounts/group-card'
// import PerformanceCard from '@/components/apps/accounts/performance-card/index.vue'
import BatchOrderModal from '@/components/apps/accounts/batch-orders-modal/index.vue'
import NewAccount from '@/components/apps/accounts/create-edit-account/index.vue'
import NewAccountGroup from '@/components/apps/accounts/create-edit-account-group/index.vue'

import NewGroup from '@/components/apps/accounts/create-edit-group/index.vue'
import AutoEntryModal from '@/components/apps/accounts/auto-entry-modal/index.vue'

const tabs = [
  {
    key: 1,
    title: 'List',
  },
  {
    key: 2,
    title: 'Performance',
  },
]
export default {
  name: 'accounts',

  meta: {
    title: 'Accounts',
  },

  components: {
    GroupCard,
    EmptyContent,
    // PerformanceCard,
  },

  data: () => ({
    chartType: 'monthly',
    chartTypes: [
      { value: 'daily', label: 'DAILY' },
      { value: 'weekly', label: 'WEEKLY' },
      { value: 'monthly', label: 'MONTHLY' },
    ],
    tabs,
    tab: tabs[0],
  }),

  computed: {
    ...mapGetters({
      groups: 'credentials/groups',
      balances: 'credentials/balances',
    }),

    accounts() {
      return this.groups.map((group) => group.accounts).flat()
    },

    isEmpty() {
      return this.groups.length < 1
    },

    buttons() {
      if (global.IS_MOBILE) {
        return []
      }
      return [
        {
          label: this.$t('New Group'),
          modal: NewGroup,
        },
        {
          hidden: this.isEmpty,
          label: this.$t('New Account'),
          modal: NewAccount,
        },
        {
          hidden: true,
          // hidden: this.isEmpty,
          label: this.$t('New Account Group'),
          modal: NewAccountGroup,
        },
        {
          // hidden: this.isEmpty,
          hidden: true,
          label: this.$t('Batch Entry'),
          modal: BatchOrderModal,
        },
        {
          hidden: this.isEmpty,
          label: this.$t('Auto Entry'),
          modal: AutoEntryModal,
        },
      ].map((button) => ({
        ...button,
        class: 'btn-sm-primary',
        handler: () => this.$$showModal({ component: button.modal }),
      }))
    },
  },

  mounted() {
    this.$store.dispatch('credentials/loadBalances')
  },

  beforeDestroy() {
    this.timer && clearInterval(this.timer)
  },
}
</script>
